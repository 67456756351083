import {
    CATEGORIES_STARTING,
    CATEGORIES_SUCCESSFULL,
    CATEGORIES_FAILED
} from '../actions/categories';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    data: []
};

export function categoriesStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function categoriesSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        data: action.payload
    });
}

export function categoriesFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function categoriesReducer(state = initialState, action) {
    switch (action.type) {
        case CATEGORIES_STARTING: return categoriesStart(state, action);
        case CATEGORIES_SUCCESSFULL: return categoriesSuccess(state, action);
        case CATEGORIES_FAILED: return categoriesFailed(state, action);

        default: return state;
    }
}