import axios from "axios";

export const PRODUCTS_STARTING = 'PRODUCTS_STARTING';
export const PRODUCTS_SUCCESSFULL = 'PRODUCTS_SUCCESSFULL';
export const PRODUCTS_FAILED = 'PRODUCTS_FAILED';

export function productsStarting(payload) {
    return {
        type: PRODUCTS_STARTING,
        payload: payload
    };
}

export function productsSuccessfull(payload) {
    return {
        type: PRODUCTS_SUCCESSFULL,
        payload: payload
    };
}

export function productsFailed(payload) {
    return {
        type: PRODUCTS_FAILED,
        payload: payload
    };
}

export const fetchProducts = (props) => {
    return async (dispatch) => {
        dispatch(productsStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/products`)
        .then(res => {
            dispatch(productsSuccessfull(res.data));
        }).catch(err => {
            dispatch(productsFailed(err.response.data.error.message));
        });
    }
};