import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import Application from "./components/pages";

import { Provider } from "react-redux";

import store from './redux/store';

import "./sass/index.css";
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Application />
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);

reportWebVitals();
