import {
    ADD_PRODUCT_STARTING,
    ADD_PRODUCT_SUCCESSFULL,
    ADD_PRODUCT_FAILED
} from '../actions/product';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    data: {}
};

export function addProductStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function addProductSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        data: action.payload
    });
}

export function addProductFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function productReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCT_STARTING: return addProductStart(state, action);
        case ADD_PRODUCT_SUCCESSFULL: return addProductSuccess(state, action);
        case ADD_PRODUCT_FAILED: return addProductFailed(state, action);

        default: return state;
    }
}