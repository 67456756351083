import {
    ADD_ITEM_WISHLIST,
    REMOVE_ITEM_WISHLIST,
    REMOVE_ITEM_ALL_WISHLIST
} from '../actions/wishlist';

import updateObject from "../updateObject";

const initialState = {
    data: localStorage.getItem("wishlist") ? JSON.parse(localStorage.getItem("wishlist")) : []
};

export function addItem(state, action) {
    localStorage.setItem("wishlist", JSON.stringify({...state.data,[action.item]: true}));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: {
            ...state.data,
            [action.item]: true
        },
    });
}

export function RemoveItem(state, action) {
    let data = state.data;

    delete data[action.item];

    localStorage.setItem("wishlist", JSON.stringify({...data}));
    return updateObject(state, {
        data: {...data}
    });
}

export function RemoveAllItems(state, action) {
    return [];
}

export function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_ITEM_WISHLIST: return addItem(state, action);
        case REMOVE_ITEM_WISHLIST: return RemoveItem(state, action);
        case REMOVE_ITEM_ALL_WISHLIST: return RemoveAllItems(state, action);
        default: return state;
    }
}