import {
    LOGIN_STARTING,
    LOGIN_SUCCESSFULL,
    LOGIN_FAILED,
    CHECKIN_STARTING,
    CHECKIN_SUCCESSFULL,
    CHECKIN_FAILED,
    NEW_TOKEN_STARTING,
    NEW_TOKEN_SUCCESSFULL,
    NEW_TOKEN_FAILED
} from '../actions/account';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    token: localStorage.getItem("token"),
    data: {},
};

export function loginStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function loginSuccess(state, action) {
    localStorage.setItem("token", action.payload.token);

    return updateObject(state, {
        inLoading: false,
        token: action.payload.token
    });
}

export function loginFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function checkInStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function checkInSuccess(state, action) {
    console.log(action.payload)
    localStorage.setItem("token", action.payload.token);

    return updateObject(state, {
        inLoading: false,
        data: action.payload,
        token: action.payload.token
    });
}

export function checkInFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function newTokenStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function newTokenSuccess(state, action) {
    localStorage.setItem("token", action.payload.token);

    return updateObject(state, {
        inLoading: false,
        token: action.payload.token
    });
}

export function newTokenFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function accountReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_STARTING: return loginStart(state, action);
        case LOGIN_SUCCESSFULL: return loginSuccess(state, action);
        case LOGIN_FAILED: return loginFailed(state, action);

        case CHECKIN_STARTING: return checkInStart(state, action);
        case CHECKIN_SUCCESSFULL: return checkInSuccess(state, action);
        case CHECKIN_FAILED: return checkInFailed(state, action);

        case NEW_TOKEN_STARTING: return newTokenStart(state, action);
        case NEW_TOKEN_SUCCESSFULL: return newTokenSuccess(state, action);
        case NEW_TOKEN_FAILED: return newTokenFailed(state, action);

        default: return state;
    }
}