import axios from "axios";

export const ADD_PRODUCT_STARTING = 'ADD_PRODUCT_STARTING';
export const ADD_PRODUCT_SUCCESSFULL = 'ADD_PRODUCT_SUCCESSFULL';
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';

export function addProductStarting(payload) {
    return {
        type: ADD_PRODUCT_STARTING,
        payload: payload
    };
}

export function addProductSuccessfull(payload) {
    return {
        type: ADD_PRODUCT_SUCCESSFULL,
        payload: payload
    };
}

export function addProductFailed(payload) {
    return {
        type: ADD_PRODUCT_FAILED,
        payload: payload
    };
}

export const addProduct = (props) => {
    return async (dispatch) => {
        dispatch(addProductStarting());

        let images = [];

        for (const image of props.images) {
            const formData = new FormData();
            formData.append("image", image);

            await axios.post(`${process.env.REACT_APP_API_KEY}/files/options`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Token " + localStorage.getItem("token")
                }
            })
            .then(res => {
                images.push(res.data.link);
            }).catch(err => {});
        }
        
        axios.post(`${process.env.REACT_APP_API_KEY}/products`,
        {
            ...props,
            images: JSON.stringify(images),
            dimensions: JSON.stringify(props.dimensions),
            sizes: JSON.stringify(props.sizes),
            colors: JSON.stringify(props.colors)
        },
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Token " + localStorage.getItem("token")
            }  
        })
        .then(res => {
            dispatch(addProductSuccessfull(res.data));
            window.location.reload();
        }).catch(err => {
            dispatch(addProductFailed(err.response.data.error.message));
        });
    }
};

export const editProduct = (props) => {
    return async (dispatch) => {
        dispatch(addProductStarting());

        let images = [];

        for (const image of props.images) {
            if (image instanceof Blob) {
                const formData = new FormData();
                formData.append("image", image);
    
                await axios.post(`${process.env.REACT_APP_API_KEY}/files/options`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": "Token " + localStorage.getItem("token")
                    }
                })
                .then(res => {
                    images.push(res.data.link);
                }).catch(err => {});
            }
            else {
                images.push(image);
            }
        }
        
        axios.put(`${process.env.REACT_APP_API_KEY}/products`,
        {
            ...props,
            images: JSON.stringify(images),
            dimensions: JSON.stringify(props.dimensions),
            sizes: JSON.stringify(props.sizes),
            colors: JSON.stringify(props.colors)
        },
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Token " + localStorage.getItem("token")
            }  
        })
        .then(res => {
            dispatch(addProductSuccessfull(res.data));
            window.location.reload();
        }).catch(err => {
            dispatch(addProductFailed(err.response.data.error.message));
        });
    }
};


export const deleteProduct = (props) => {
    return async (dispatch) => {
        dispatch(addProductStarting());

        axios.delete(`${process.env.REACT_APP_API_KEY}/products`,
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Token " + localStorage.getItem("token")
            },
            data: {
                ...props
            }
        })
        .then(res => {
            dispatch(addProductSuccessfull(res.data));
            window.location.reload();
        }).catch(err => {
            dispatch(addProductFailed(err.response.data.error.message));
        });
    }
};