import axios from "axios";

export const REVIEWS_STARTING = 'REVIEWS_STARTING';
export const REVIEWS_SUCCESSFULL = 'REVIEWS_SUCCESSFULL';
export const REVIEWS_FAILED = 'REVIEWS_FAILED';

export function reviewsStarting(payload) {
    return {
        type: REVIEWS_STARTING,
        payload: payload
    };
}

export function reviewsSuccessfull(payload) {
    return {
        type: REVIEWS_SUCCESSFULL,
        payload: payload
    };
}

export function reviewsFailed(payload) {
    return {
        type: REVIEWS_FAILED,
        payload: payload
    };
}

export const fetchReviews = (props) => {
    return async (dispatch) => {
        dispatch(reviewsStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/reviews?product_id=${props}`)
        .then(res => {
            dispatch(reviewsSuccessfull(res.data));
        }).catch(err => {
            dispatch(reviewsFailed(err.response.data.error.message));
        });
    }
};