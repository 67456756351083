import {
    PROMO_STARTING,
    PROMO_SUCCESSFULL,
    PROMO_FAILED
} from '../actions/promo';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    success: null,
    data: {}
};

export function promoStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function promoSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        success: true,
        data: action.payload
    });
}

export function promoFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        success: false
    });
}

export function promoReducer(state = initialState, action) {
    switch (action.type) {
        case PROMO_STARTING: return promoStart(state, action);
        case PROMO_SUCCESSFULL: return promoSuccess(state, action);
        case PROMO_FAILED: return promoFailed(state, action);

        default: return state;
    }
}