import {
    SET_CART,
    ADD_ITEM_CART,
    REMOVE_ITEM_CART,
    REMOVE_ITEM_ALL_CART
} from '../actions/cart';

import updateObject from "../updateObject";

const initialState = {
    data: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
};

export function set(state, action) {
    localStorage.setItem("cart", JSON.stringify(action.payload));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: action.payload,
    });
}

export function addItem(state, action) {
    let data = state.data;
    let exists = false;

    if (data) {
        for (const obj of data) {
            if (obj.id === action.item && obj.size === action.size && obj.color === action.color) {
                obj.amount++;
                exists = true;
            }
        }
    }

    if (!exists) {
        data.push({id: action.item, amount: 1, size: action.size, color: action.color});
    }

    localStorage.setItem("cart", JSON.stringify(data));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: [...data],
    });
}

export function RemoveItem(state, action) {
    let data = [];

    for (let obj of state.data) {
        if (obj.id === action.item && obj.size === action.size && obj.color === action.color) {
            obj.amount--;

            if (obj.amount != 0) {
                data.push(obj);
            }
        }
        else {
            data.push(obj);
        }
    }

    localStorage.setItem("cart", JSON.stringify(data));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: [...data],
    });
}

export function RemoveAllItems(state, action) {
    localStorage.setItem("cart", JSON.stringify([]));
    window.dispatchEvent( new Event('storage') );
    return [];
}

export function cartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CART: return set(state, action);
        case ADD_ITEM_CART: return addItem(state, action);
        case REMOVE_ITEM_CART: return RemoveItem(state, action);
        case REMOVE_ITEM_ALL_CART: return RemoveAllItems(state, action);
        default: return state;
    }
}