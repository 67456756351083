import axios from "axios";

export const CHECKOUT_STARTING = 'CHECKOUT_STARTING';
export const CHECKOUT_SUCCESSFULL = 'CHECKOUT_SUCCESSFULL';
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED';

export function checkoutStarting(payload) {
    return {
        type: CHECKOUT_STARTING,
        payload: payload
    };
}

export function checkoutSuccessfull(payload) {
    return {
        type: CHECKOUT_SUCCESSFULL,
        payload: payload
    };
}

export function checkoutFailed(payload) {
    return {
        type: CHECKOUT_FAILED,
        payload: payload
    };
}

export const checkout = (props) => {
    return async (dispatch) => {
        dispatch(checkoutStarting());

        axios.post(`${process.env.REACT_APP_API_KEY}/orders`,
        {
            ...props
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        .then(res => {
            localStorage.removeItem("cart");
            dispatch(checkoutSuccessfull(res.data));

            window.location.href = "/order?id=" + res.data.insertId;
        }).catch(err => {
            dispatch(checkoutFailed(err.response.data.error.message));
        });
    }
};