import {
    PAGE_STARTING,
    PAGE_SUCCESSFULL,
    PAGE_FAILED
} from '../actions/page';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    data: []
};

export function pageStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function pageSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        data: action.payload
    });
}

export function pageFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case PAGE_STARTING: return pageStart(state, action);
        case PAGE_SUCCESSFULL: return pageSuccess(state, action);
        case PAGE_FAILED: return pageFailed(state, action);

        default: return state;
    }
}